import { Link, navigate, useLocation } from '@reach/router';
import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import BIRMINGHAM_AES_APP_LOGO from '../../svgs/BIRMINGHAM_AES_LOGO.png';
import BIRMINGHAM_ESOL_APP_LOGO from '../../svgs/BIRMINGHAM_ESOL_LOGO.png';
import BOLTON_APP_LOGO from '../../svgs/BOLTON_APP_LOGO.png';
import APP_LOGO from '../../svgs/MAE_APP_LOGO.png';
import NOTTINGHAM_APP_LOGO from '../../svgs/NOTTINGHAM_APP_LOGO.png';
import ROCHDALE_APP_LOGO from '../../svgs/RAE_APP_LOGO.png';
import SALFORD_APP_LOGO from '../../svgs/SALFORD_APP_LOGO.png';
import Loading from '../common/Loading';
import * as CSS from './elements/LoginForm';
const { REACT_APP_THEME = 'talkEnglish' } = process.env;

const loginSchema = yup.object().shape({
  phoneNumber: yup.string().required('Please enter your phone number'),
  pin: yup
    .string()
    .matches(/^[\d]*$/g, 'PINs can only contain numbers')
    .required('Please enter your PIN'),
});

const staffLoginSchema = yup.object().shape({
  phoneNumber: yup.string().required('Please enter your phone number'),
  pin: yup.string().required('Please enter your Password'),
});

const LoginForm = observer(({ userSignIn, isStaff }) => {
  const location = useLocation();
  const handleSubmit = ({ phoneNumber, pin }, actions) => {
    return userSignIn({ phoneNumber, pin })
      .then(() => {
        localStorage.setItem('isStaff', isStaff);
      })
      .catch((err) => {
        let errMsg = err.response.data.msg;
        if (errMsg === 'Account locked') {
          errMsg += ', please contact a member of staff';
        }
        return actions.setFieldError('serverError', errMsg);
      });
  };

  const handleInvalid = (e, submitForm) => {
    e.preventDefault();
    submitForm();
  };

  const clearIsStaff = () => {
    localStorage.removeItem('isStaffLogin');
  };

  useEffect(() => {
    const storedStaffLogin = localStorage.getItem('isStaffLogin');
    if (storedStaffLogin) {
      const isStaff = JSON.parse(storedStaffLogin);
      if (isStaff && !location.pathname.startsWith('/staff')) {
        navigate('/staff');
      }
    }
  }, [location]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phoneNumber: '',
        pin: '',
        serverError: null,
      }}
      validationSchema={isStaff ? staffLoginSchema : loginSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Observer>
          {() => (
            <CSS.Form data-testid="LoginForm" autoComplete="on">
              <CSS.LogoImg
                src={
                  REACT_APP_THEME === 'rochdale'
                    ? ROCHDALE_APP_LOGO
                    : REACT_APP_THEME === 'birminghamESOL'
                    ? BIRMINGHAM_ESOL_APP_LOGO
                    : REACT_APP_THEME === 'birminghamAES'
                    ? BIRMINGHAM_AES_APP_LOGO
                    : REACT_APP_THEME === 'bolton'
                    ? BOLTON_APP_LOGO
                    : REACT_APP_THEME === 'salford'
                    ? SALFORD_APP_LOGO
                    : REACT_APP_THEME === 'nottingham'
                    ? NOTTINGHAM_APP_LOGO
                    : APP_LOGO
                }
                alt="App logo"
              />
              <CSS.FormHeader>Welcome!</CSS.FormHeader>
              {isStaff && (
                <CSS.FormSubHeader as="h2">Staff Login</CSS.FormSubHeader>
              )}
              <CSS.FieldGroup>
                <Field
                  type="text"
                  placeholder="07123456789"
                  name="phoneNumber"
                  autoComplete="tel"
                  label="Phone Number"
                  component={CSS.StaffTextFieldInput}
                  id="phoneNumber"
                  inputMode="numeric"
                />
                <ErrorMessage
                  name="phoneNumber"
                  data-testid="phone-number-error-msg"
                  component="p"
                />
              </CSS.FieldGroup>
              <CSS.FieldGroup>
                <Field
                  type="password"
                  placeholder={isStaff ? '' : '000000'}
                  name="pin"
                  autoComplete="current-password"
                  label={isStaff ? 'Password' : 'PIN'}
                  component={CSS.StaffTextFieldInput}
                  id="pin"
                  inputMode={isStaff ? 'text' : 'numeric'}
                  onInvalid={(e) => handleInvalid(e, submitForm)}
                />
                <ErrorMessage
                  name="pin"
                  data-testid="pin-error-msg"
                  component="p"
                />
              </CSS.FieldGroup>
              <ErrorMessage
                name="serverError"
                data-testid="server-error-msg"
                component="p"
              />
              {isSubmitting ? (
                <CSS.LoadingContainer>
                  <Loading />
                </CSS.LoadingContainer>
              ) : (
                <CSS.LoginButton data-testid="login-submit" type="submit">
                  Sign In <CSS.NextArrowForwardIosIcon />
                </CSS.LoginButton>
              )}
              {isStaff && (
                <Link to={isStaff ? '/' : '/staff'}>
                  <CSS.StudentLoginButton onClick={clearIsStaff}>
                    {isStaff ? 'Student Login' : 'Staff Login '}
                  </CSS.StudentLoginButton>
                </Link>
              )}
            </CSS.Form>
          )}
        </Observer>
      )}
    </Formik>
  );
});

export default LoginForm;
