import { observer, useLocalStore } from 'mobx-react-lite';
import React, { createContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';
const { REACT_APP_THEME = 'talkEnglish' } = process.env;

export const ThemeContext = createContext({});

export const ThemeProvider = observer(({ children }) => {
  const themeStore = useLocalStore(() => ({
    currentTheme: REACT_APP_THEME,
    changeTheme(newTheme) {
      if (themes[newTheme]) {
        themeStore.currentTheme = newTheme;
      }
    },
  }));

  const themes = {
    example: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#2D354F',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#2862bd',
      buttonCreate:
        'linear-gradient(342.63deg, #21B65A 4.49%, rgba(33, 182, 90, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, rgba(149, 80, 249, 0.9) -8.62%, rgba(115, 188, 255, 0.9) 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#eb5757',
      buttonDelete: '#e80909',
      classroomHighlight: '#F17030',
      lessonHighlight: '#9550F9',
      targetHighlight: '#C505C6',
      completedHighlight: '#d9facb',
      completedFill: '#B2E59C',
      completedDark: '#86BA70',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#727889',
      userMessage: '#2388fb',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(33, 182, 90, 0.53)',
      feedbackLight: '#fff5d6',
      feedbackDark: '#F7CC49',
      isTaskDark: false,
      isClassRoomDark: true,
      isLessonDark: true,
      notification: '#e80909',
    },
    northcoders: {},
    talkEnglish: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#2D354F',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#2862bd',
      buttonCreate:
        'linear-gradient(342.63deg, #21B65A 4.49%, rgba(33, 182, 90, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, rgba(105,172,143,0.9) -8.62%, rgba(66,152,175,0.9) 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#ca3f3f',
      buttonDelete: '#e80909',
      classroomHighlight: '#10426f',
      lessonHighlight: '#438166',
      targetHighlight: '#F7CC49',
      targetHighlightPale: '#fff5d6',
      completedHighlight: '#b4daac',
      completedFill: '#b4daac',
      completedDark: '#86BA70',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#545965',
      userMessage: '#3b579d',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(16, 66, 111, 0.2)',
      feedbackLight: '#ffffff',
      feedbackDark: '#F7CC49',
      isTaskDark: false,
      isTargetDark: false,
      isClassRoomDark: true,
      isLessonDark: true,
      notification: '#e80909',
    },
    rochdale: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#2D354F',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#7D3386',
      buttonCreate:
        'linear-gradient(342.63deg, #7D3386 4.49%, rgba(125, 51, 134, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, rgba(51,58,134,0.9) -8.62%, rgba(125, 51, 134,0.9) 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#eb5757',
      buttonDelete: '#e80909',
      classroomHighlight: '#7D3386',
      lessonHighlight: '#663a6a',
      targetHighlight: '#333a86',
      targetHighlightPale: '#424ba6',
      completedHighlight: '#87488c',
      completedFill: '#87488c',
      completedDark: '#663a6a',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#727889',
      userMessage: '#663a6a',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(16, 66, 111, 0.2)',
      feedbackLight: '#ffffff',
      feedbackDark: '#F7CC49',
      isTaskDark: true,
      isTargetDark: true,
      isClassRoomDark: true,
      isLessonDark: true,
      notification: '#e80909',
    },
    birminghamESOL: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#2D354F',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#2862bd',
      buttonCreate:
        'linear-gradient(342.63deg, #21B65A 4.49%, rgba(33, 182, 90, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, #BDD1E2 -8.62%, #3B67B0 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#ca3f3f',
      buttonDelete: '#e80909',
      classroomHighlight: '#264f94',
      lessonHighlight: '#3B67B0',
      targetHighlight: '#FF6600',
      targetHighlightPale: '#ff9249',
      completedHighlight: '#BDD1E2',
      completedFill: '#BDD1E2',
      completedDark: '#3B67B0',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#545965',
      userMessage: '#3b579d',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(16, 66, 111, 0.2)',
      feedbackLight: '#ffffff',
      feedbackDark: '#F7CC49',
      isTaskDark: false,
      isTargetDark: true,
      isClassRoomDark: true,
      isLessonDark: true,
      notification: '#e80909',
    },
    birminghamAES: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#2D354F',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#2862bd',
      buttonCreate:
        'linear-gradient(342.63deg, #21B65A 4.49%, rgba(33, 182, 90, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, #99ADD9 -8.62%, #1765AF 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#ca3f3f',
      buttonDelete: '#e80909',
      classroomHighlight: '#1765AF',
      lessonHighlight: '#99ADD9',
      targetHighlight: '#FFD600',
      targetHighlightPale: '#fef4c3',
      completedHighlight: '#BDD1E2',
      completedFill: '#BDD1E2',
      completedDark: '#1765AF',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#545965',
      userMessage: '#3b579d',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(16, 66, 111, 0.2)',
      feedbackLight: '#ffffff',
      feedbackDark: '#F7CC49',
      isTaskDark: false,
      isTargetDark: false,
      isClassRoomDark: true,
      isLessonDark: true,
      notification: '#e80909',
    },
    bolton: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#2D354F',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#5C98BD',
      buttonCreate:
        'linear-gradient(342.63deg, #21B65A 4.49%, rgba(33, 182, 90, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, rgba(176, 149, 60, 0.9) -8.62%, rgba(255, 183, 18, 0.9) 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#ca3f3f',
      buttonDelete: '#e80909',
      classroomHighlight: '#5C98BD',
      lessonHighlight: '#FFB712',
      targetHighlight: '#C61331',
      targetHighlightPale: '#B0953C',
      completedHighlight: '#B0953C',
      completedFill: '#B0953C',
      completedDark: '#FFB712',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#545965',
      userMessage: '#3b579d',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(16, 66, 111, 0.2)',
      feedbackLight: '#ffffff',
      feedbackDark: '#FFB712',
      isTaskDark: true,
      isTargetDark: true,
      isClassRoomDark: true,
      isLessonDark: true,
      notification: '#e80909',
    },
    salford: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#2D354F',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#0F7782',
      buttonCreate:
        'linear-gradient(342.63deg, #21B65A 4.49%, rgba(33, 182, 90, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, rgba(176, 149, 60, 0.9) -8.62%, rgba(255, 183, 18, 0.9) 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#ca3f3f',
      buttonDelete: '#e80909',
      classroomHighlight: '#0F7782',
      lessonHighlight: '#EEC71E',
      targetHighlight: '#E6007E',
      targetHighlightPale: '#5B6453',
      completedHighlight: '#5B6453',
      completedFill: '#5B6453',
      completedDark: '#EEC71E',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#545965',
      userMessage: '#3b579d',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(16, 66, 111, 0.2)',
      feedbackLight: '#ffffff',
      feedbackDark: '#EEC71E',
      isTaskDark: true,
      isTargetDark: true,
      isClassRoomDark: true,
      isLessonDark: true,
      notification: '#e80909',
    },
    nottingham: {
      appBackground: '#FFFFFF',
      chatBackground: '#f8f8f8',
      staffBackground: '#E5E5E5',
      headerColour: '#53565a',
      subHeaderColour: '#5b5b5b',
      staffCardHeaderColour: '#373737',
      buttonPrimary: '#c4d600',
      buttonCreate:
        'linear-gradient(342.63deg, #c4d600 4.49%, rgba(186, 203, 6, 0.53) 96.86%)',
      buttonUpdate:
        'linear-gradient(280.49deg, rgba(196, 214, 0, 0.9) -8.62%, rgba(186, 203, 6, 0.9) 95.82%)',
      buttonDisabled:
        'linear-gradient(286.7deg, #C1C1C1 0%, rgba(193, 193, 193, 0.4) 100%)',
      buttonWarningGradient:
        'linear-gradient(280.49deg, rgba(232, 9, 9, 0.9) -8.62%, rgba(235,87,87, 0.9) 95.82%)',
      buttonWarning: '#ca3f3f',
      buttonDelete: '#e80909',
      classroomHighlight: '#c4d600',
      lessonHighlight: '#53565a',
      targetHighlight: '#0096d8',
      targetHighlightPale: '#33a5d7',
      completedHighlight: '#c4d600',
      completedFill: '#c4d600',
      completedDark: '#bacb06',
      pendingFill: '#C9C9C9',
      pendingDark: '#707070',
      textLight: '#FFFFFF',
      textDark: '#545965',
      userMessage: '#3b579d',
      errorMessage: '#e80909',
      incomingMessage: '#e8e8e8',
      fontFamily: 'Muli, Arial, sans-serif',
      hoverFill: 'rgba(16, 66, 111, 0.2)',
      feedbackLight: '#ffffff',
      feedbackDark: '#EEC71E',
      isTaskDark: true,
      isTargetDark: true,
      isClassRoomDark: false,
      isLessonDark: true,
      notification: '#e80909',
    },
    // #c4d600
    // #0096d8
    // #53565a
    // #bacb06
  };

  return (
    <ThemeContext.Provider value={themes[themeStore.currentTheme]}>
      <StyledThemeProvider theme={themes[themeStore.currentTheme]}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
});
