import firebase from 'firebase/app';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer, Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { PrivacyPoliciesContext } from '../../contexts/PrivacyPolicies.js';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { db } from '../../firebase/index.js';
import { formatDate } from '../../utils/format-date.js';
import * as CSS from './elements/PolicyCard.js';

const policySchema = yup.object().shape({
  policy: yup.string().required('Cannot be left empty'),
});

const PolicyCard = observer(({ policyId }) => {
  const saveChangesStore = useContext(SaveChangesContext);
  const privacyPoliciesStore = useContext(PrivacyPoliciesContext);
  const privacyPolicy = privacyPoliciesStore.getPolicyById(policyId);

  if (!privacyPolicy) return <p>Policy not found</p>;

  const formattedDate = formatDate(privacyPolicy.createdAt);

  const handleSubmit = async (values) => {
    await db
      .collection('privacyPolicies')
      .doc(policyId)
      .update({
        policy: values.policy,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      });
  };
  return (
    <CSS.PolicyCard data-testid="PolicyCard">
      <Formik
        enableReinitialize
        initialValues={{
          policy: privacyPolicy.policy || '',
        }}
        validationSchema={policySchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, dirty }) => {
          saveChangesStore.setEdited(dirty);
          return (
            <Observer>
              {() => (
                <Form data-testid="LearningAssessment-form">
                  <CSS.PolicyTopRow>
                    <CSS.PolicyInfo>
                      Last updated: {formattedDate}
                    </CSS.PolicyInfo>
                    <CSS.SaveChangesButton type="submit" disabled={!dirty}>
                      {dirty ? 'Save' : 'Saved'}
                    </CSS.SaveChangesButton>
                  </CSS.PolicyTopRow>
                  <ErrorMessage name="policy" component="p" />
                  <Field
                    data-testid="policy"
                    component={CSS.TextArea}
                    name="policy"
                    rowsMin={3}
                    rowsMax={10}
                    disabled={false}
                    aria-label="privacy policy"
                  />
                </Form>
              )}
            </Observer>
          );
        }}
      </Formik>
    </CSS.PolicyCard>
  );
});

export default PolicyCard;
